import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import { LANGUAGES_OPTIONS, mobileMenuCls } from '../../constants';
import { debounce, forceLanguage, getDOM, getDOMCls, Permalink, sortAlphabetically } from '../../helpers';
import SearchInput from '../Search/Search';
import { Namespace } from '../../enums';
import { closeMenu, getAuthorizedItems } from './headerHelpers';
import { GenreBase } from '../../typings/api';
import { useAuth } from '../../context/authContext';
import AppLink from '../AppLink/AppLink';
import { useLang } from 'context/langContext';
import PlatformHelper from 'helpers/PlatformHelper';
import { InfoToolbar } from '..';
import { getSharedApiClient } from '../../api/client';
import Select from '../Select/Select';
import { Country } from '../../enums/country';

interface Props {
  countryCode: string;
}

const Header = ({ countryCode }: Props): JSX.Element => {
  const headerRef = React.useRef<HTMLElement>(null);
  const navigationRef = React.useRef<HTMLElement>(null);
  const [mainGenres, setMainGenres] = React.useState<GenreBase[]>([]);
  const [didScroll, setDidScroll] = React.useState<boolean>(false);
  const { t } = useTranslation(Namespace.Common);
  const { user, isUserLoaded } = useAuth();
  const { locale } = useLang();
  const [genres, setGenres] = useState<GenreBase[]>();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const getLangFlag = useCallback(() => LANGUAGES_OPTIONS.find((item) => item.value === locale).flag, [locale]);

  const onLanguageChange = useMemo(
    () => async (language: string) => {
      if (!user) {
        forceLanguage(language);
      } else {
        await getSharedApiClient().updateUserData({
          language,
        });
      }
      window.location.reload();
    },
    [user, isUserLoaded],
  );

  useEffect(() => {
    if (locale) {
      getSharedApiClient().getGenres().then(setGenres);
    }
  }, [locale]);

  useEffect(() => {
    setMainGenres(genres?.filter((g) => ['drama', 'comedy', 'tragedy'].indexOf(g.slug) > -1) ?? []);
  }, [genres]);

  const toggleMobileMenu = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setMobileMenuOpen((state) => !state);
  };

  useEffect(() => {
    const htmlClsList = getDOMCls();
    if (!htmlClsList.contains(mobileMenuCls)) {
      if (PlatformHelper.isMobileSafari) {
        window.scroll(0, 1);
      }
      htmlClsList.add(mobileMenuCls);
    } else {
      htmlClsList.remove(mobileMenuCls);
    }
  }, [mobileMenuOpen]);

  const onCollapseMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    if (mobileMenuOpen) {
      e.stopPropagation();
      e.preventDefault();

      const parent = (e.target as HTMLAnchorElement).parentElement as HTMLLIElement;
      const collapseCls = 'collapse-menu-opened';

      if (parent.classList.contains(collapseCls)) {
        parent.classList.remove(collapseCls);
      } else {
        parent.classList.add(collapseCls);
      }
      const allGenres = getDOM().querySelector('.all-genres-opened');
      if (allGenres && parent.classList.contains('genres')) {
        allGenres.classList.remove('all-genres-opened');
      }
    }
  };

  const handleScroll = () => {
    const header = headerRef?.current;

    if (header) {
      const navElementHeight = header?.clientHeight || null;

      window.scrollY > navElementHeight ? setDidScroll(true) : setDidScroll(false);
    } else {
      setDidScroll(window.scrollY > 64);
    }
  };

  React.useEffect(() => {
    const listenerCallback = debounce(handleScroll);

    if (window) {
      window.addEventListener('scroll', listenerCallback, false);

      return () => {
        window.removeEventListener('scroll', listenerCallback, false);
      };
    }
  }, [debounce, handleScroll]);

  React.useEffect(() => {
    const htmlClsList = getDOMCls();
    if (htmlClsList.contains(mobileMenuCls)) {
      htmlClsList.remove(mobileMenuCls);
    }

    return () => {
      htmlClsList.remove(mobileMenuCls);
    };
  }, []);

  const sortedGenres = sortAlphabetically(genres, 'name');

  /*
    Hide core menu items when user is registered but still hasn't activated subscription within the first week (typically /choose-plan after first login).
    Show core menu items in all other cases.
  */
  const showCoreMenuEntries = useMemo(() => {
    return !user || !user.isWeekOldWithoutSub;
  }, [user]);

  return (
    <header className={classNames('fixed-top', { fixed: didScroll, blendLogo: countryCode !== 'pl' })} ref={headerRef}>
      {/* {countryCode === Country.Ukraine && (
        <InfoToolbar translationKey="ua-support:title" link={Permalink.uaSupport(locale)} />
      )} */}
      {/* {countryCode === Country.Czechia && (
        <InfoToolbar
          translationKey="christmas-contest:contest_info_toolbar_2023"
          link={Permalink.christmasContest(locale)}
        />
      )} */}
      <div className="header-content">
        <div>
          <a href="/#/toggle-nav" className="nav-toggle" id="nav-toggle" onClick={toggleMobileMenu}>
            <span className="toggle-lang">{getLangFlag()}</span>
          </a>
          {showCoreMenuEntries && <label htmlFor="input-search" className="search" />}
          <a href={Permalink.registerStep1(locale)} className={'btn-register-header'}>
            {t('common:menu.try_now')}
          </a>
          <nav className={classNames('menu', user && 'logged-in')} role="main" ref={navigationRef}>
            <ul>
              {showCoreMenuEntries && (
                <>
                  <li className="menu-home">
                    <AppLink href={Permalink.root(user, locale)}>
                      <a>{t('menu.home')}</a>
                    </AppLink>
                  </li>
                  <li className="menu-search">
                    <label htmlFor="input-search">{t('common:menu.search')}</label>
                  </li>

                  <li className="menu-item collapse-menu genres">
                    <a href="#" onClick={onCollapseMenuClick}>
                      {t('menu.genres')}
                    </a>
                    <ul className="primary-list">
                      <li className="primary-item">
                        <AppLink href={Permalink.mostPopular(locale)}>
                          <a>{t('popular')}</a>
                        </AppLink>
                      </li>
                      <li className="border-bottom-1px primary-item">
                        <AppLink href={Permalink.recentlyAdded(locale)}>
                          <a>{t('recently_added')}</a>
                        </AppLink>
                      </li>
                      {mainGenres.map((g, i) => (
                        <li className={classNames('primary-item', i === 2 ? 'border-bottom-1px' : null)} key={i}>
                          <a href={Permalink.genre(g, locale)} onClick={closeMenu}>
                            {g.name}
                          </a>
                        </li>
                      ))}
                      <li className="all-genres with-icon collapsible-button">
                        <a onClick={onCollapseMenuClick}>{t('all_genres')}</a>
                        <ul className={classNames('sub-menu', sortedGenres?.length <= 5 ? 'shifted' : null)}>
                          {sortedGenres?.map((genre) => (
                            <li className="genre-link" key={genre.id}>
                              <a href={Permalink.genre(genre, locale)} onClick={closeMenu}>
                                {genre.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item">
                    <AppLink href={Permalink.theatres(locale)}>
                      <a>{t('menu.theatres')}</a>
                    </AppLink>
                  </li>
                  {isUserLoaded && user ? (
                    <li className="menu-item">
                      <AppLink href={Permalink.watchList(locale)}>
                        <a>{t('menu.watch_list')}</a>
                      </AppLink>
                    </li>
                  ) : null}
                  <li className="menu-item collapse-menu">
                    <a href="/#/about" onClick={onCollapseMenuClick}>
                      {t('menu.about')}
                    </a>
                    <ul className="primary-list about-dramox">
                      <li className="border-bottom-1px primary-item">
                        <AppLink href={Permalink.about(locale)}>
                          <a>{t('menu.about_project')}</a>
                        </AppLink>
                      </li>
                      {/* {countryCode === Country.Czechia && (
                        <li className="christmas-contest with-icon primary-item">
                          <AppLink href={Permalink.christmasContest(locale)}>
                            <a>{t('menu.christmas_contest')}</a>
                          </AppLink>
                        </li>
                      )} */}
                      <li className="with-icon support-theater primary-item">
                        <AppLink href={Permalink.donate(locale)}>
                          <a>{t('menu.theatre')}</a>
                        </AppLink>
                      </li>
                      <li className="with-icon gift-vouchers primary-item">
                        <AppLink href={Permalink.voucher(locale)}>
                          <a>{t('menu.voucher')}</a>
                        </AppLink>
                      </li>
                      <li className="for-schools with-icon border-bottom-1px primary-item">
                        <AppLink href={Permalink.forSchools(locale)}>
                          <a>{t('menu.for_schools')}</a>
                        </AppLink>
                      </li>
                      <li className="primary-item">
                        <AppLink href={Permalink.howToWatchMobile(locale)}>
                          <a>{t('menu.mobile_apps')}</a>
                        </AppLink>
                      </li>
                      <li className="primary-item">
                        <AppLink href={Permalink.howToWatchSmartTV(locale)}>
                          <a>{t('menu.smart_tv')}</a>
                        </AppLink>
                      </li>
                      <li className="primary-item">
                        <AppLink href={Permalink.contact(locale)}>
                          <a>{t('menu.contact')}</a>
                        </AppLink>
                      </li>
                      <li className="primary-item">
                        <AppLink href={Permalink.faq(locale)}>
                          <a>{t('menu.faq')}</a>
                        </AppLink>
                      </li>
                    </ul>
                  </li>
                </>
              )}
              {isUserLoaded && (
                <>
                  <li className="menu-item with-icon gift-vouchers" title={t('menu.voucher')}>
                    <AppLink href={Permalink.voucher(locale)}>
                      <a>{t('menu.voucher')}</a>
                    </AppLink>
                  </li>
                  <li
                    className="menu-item with-icon set-language"
                    style={{ marginRight: isUserLoaded ? 'initial' : 'auto' }}
                  >
                    <div className="lang-flag">{getLangFlag()}</div>
                    <div className="language-list">
                      {LANGUAGES_OPTIONS.map((val, i) => (
                        <a
                          key={i}
                          onClick={() => {
                            onLanguageChange(val.value);
                          }}
                          dangerouslySetInnerHTML={{ __html: val.label }}
                        ></a>
                      ))}
                    </div>
                  </li>
                </>
              )}

              {showCoreMenuEntries && (
                <li className="search-container">
                  <SearchInput />
                </li>
              )}

              {getAuthorizedItems(
                t,
                /* showPaidSubscriptionRelatedItems */ showCoreMenuEntries,
                user?.home_country ?? countryCode,
              )}
              <li>
                <div className="languages">
                  <label htmlFor="language"></label>
                  <span>{t('profile:ui_language')}</span>
                  {isUserLoaded && (
                    <Select
                      name="language"
                      options={LANGUAGES_OPTIONS}
                      defaultValue={locale}
                      className="tiny"
                      onChange={(lang) => onLanguageChange(lang)}
                    />
                  )}
                </div>
              </li>
            </ul>
          </nav>
        </div>
        <AppLink
          href={!mobileMenuOpen ? Permalink.root(user, locale) : '#'}
          onClick={mobileMenuOpen ? toggleMobileMenu : null}
        >
          <a
            className={['logo', locale === 'uk' ? 'small-logo' : ''].join(' ')}
            title="Dramox"
            mobile-text={t('common:menu.logo_mobile')}
          >
            Dramox
          </a>
        </AppLink>
      </div>
    </header>
  );
};

export default Header;
